<template>
  <div style="overflow: hidden" v-if="Boolean(total)">
    <div class="pagination-box">
      <!-- eslint-disable-next-line vue/no-mutating-props -->
      <a-pagination
        :current="params.curPage"
        :total="total"
        :pageSize="params.pageSize"
        @showSizeChange="showSizeChange"
        show-size-changer
        show-quick-jumper
        @change="onChange"
      >
        <template #itemRender="{ type, originalElement }">
          <a v-if="type === 'prev'">上一页</a>
          <a v-else-if="type === 'next'">下一页</a>
          <renderVNode v-else :vnode="originalElement"></renderVNode>
        </template>
      </a-pagination>
    </div>
  </div>
</template>

<script>
const renderVNode = (_, { attrs: { vnode } }) => {
  return vnode;
};
export default {
  components: {
    renderVNode
  },
  props: {
    params: {
      type: Object,
      required: true
    },
    total: {
      type: Number,
      required: true
    }
  },

  setup(props, context) {
    const showSizeChange = (current, size) => {
      props.params.pageSize = size;
      context.emit('getList');
    };
    const onChange = (page, pageSize) => {
      props.params.curPage = page;
      context.emit('getList');
    };

    return {
      showSizeChange,
      onChange
    };
  }
};
</script>
<style lang="less" scoped>
.pagination-box {
  width: 1400px;
  margin: 30px auto 115px;
  display: flex;
  justify-content: flex-end;
  ::v-deep(.ant-pagination-prev),
  ::v-deep(.ant-pagination-next) {
    width: 80px;
    .pagination-minx();
  }
  ::v-deep(.ant-pagination-item) {
    color: #333333;
    .pagination-minx();
  }
  ::v-deep(.ant-pagination-item-active) {
    background-color: #007fff;
    a {
      color: #fff;
    }
  }
  ::v-deep(.ant-select-selector) {
    .pagination-minx();
  }
  ::v-deep(.ant-pagination-options-quick-jumper) {
    input {
      .pagination-minx();
    }
  }
}
// 页脚公共部分
.pagination-minx {
  background-color: #f6f6f6;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
}
</style>
