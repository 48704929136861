<template>
  <div class="search-box">
    <div class="search-screen">
      <div class="time-screen-box">
        <a-date-picker
          dropdownClassName="live-broadcast-picker"
          v-model:value="startValue"
          :disabled-date="disabledStartDate"
          format="YYYY-MM-DD"
          placeholder="请选入开始时间"
        />
        <div class="horizontal-line">-</div>
        <a-date-picker
          dropdownClassName="live-broadcast-picker"
          v-model:value="endValue"
          :disabled-date="disabledEndDate"
          format="YYYY-MM-DD"
          :open="endOpen"
          placeholder="请选入结束时间"
          @openChange="handleEndOpenChange"
        />
      </div>
      <div class="tag-filtering-box">
        <a-select
          mode="multiple"
          :maxTagCount="1"
          placeholder="请选择标签"
          v-model:value="activeLable"
          style="width: 100%"
        >
          <a-select-option v-for="item in labelList" :key="item" :value="item.id">
            {{ item.labelName }}
          </a-select-option>
        </a-select>
      </div>
      <div class="publisher-filtering-box">
        <a-tree-select
          v-model:value="userIdList"
          style="width: 100%"
          :tree-data="treeData"
          tree-checkable
          :maxTagCount="1"
          check
          :replaceFields="replaceFields"
          allow-clear
          placeholder="请选择发布者"
        />
      </div>
      <div style="display: flex">
        <a-input v-model:value="searchContent" :placeholder="placeholder" />
        <a-button type="primary" @click="screenSearch" style="margin-left: 20px;">搜索</a-button>
      </div>
    </div>
    <a-button type="primary" class="launch-live" v-if="getButtonVisible" @click="sendJump(jumpObject.pathName)">{{
      jumpObject.name
    }}</a-button>
  </div>
</template>

<script>
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons-vue';
import { useRouter } from 'vue-router';
import { ref, inject, onMounted, computed } from 'vue';
import Label from '@/components/Label.vue';
import { FormatTime } from '@/utils/tool';
import api from '@/services';
// 替换数组建原有字段
const replaceFields = {
  children: 'children',
  title: 'title',
  value: 'key',
  key: 'key'
};
export default {
  components: {
    CaretDownOutlined,
    CaretUpOutlined,
    Label
  },
  props: {
    jumpObject: {
      type: Object,
      default: () => {
        return {
          name: '',
          pathName: ''
        };
      }
    },
    // 视频还是直播
    isShow: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String
    }
  },

  setup(props, context) {
    const getButtonVisible = computed(() => {
      let fnCodeList = JSON.parse(localStorage.getItem('fnCodeList')) || [];
      let flag = true;
      // 如果是发起直播按钮，判断有无权限
      if (props.jumpObject.name === '发起直播') {
        flag = fnCodeList.includes('LIVE_START');
      } else if (props.jumpObject.name === '上传视频') {
        flag = fnCodeList.includes('VIDEO_PUSH');
      }
      return Boolean(props.jumpObject.name) && flag;
    });

    // 日期
    const startValue = ref();
    const endValue = ref();
    const endOpen = ref(false);

    const disabledStartDate = startValue => {
      if (!startValue || !endValue.value) {
        return false;
      }
      return startValue.valueOf() > endValue.value.valueOf();
    };
    const disabledEndDate = endValue => {
      if (!endValue || !startValue.value) {
        return false;
      }
      return startValue.value.valueOf() >= endValue.valueOf();
    };
    const handleEndOpenChange = open => {
      endOpen.value = open;
    };

    // 标签筛选
    const labelList = inject('labelList');
    const activeLable = ref([]);
    // 搜索框
    const searchContent = ref('');
    // 类型
    const type = ref(null);

    // 查询方法
    const params = inject('params');
    const screenSearch = () => {
      let userIdList_tr = [];
      if (userIdList.value) {
        userIdList_tr = userIdList.value.map(Number).filter(item => item);
      }
      const keys_params = Object.keys(params.value);
      params.value[keys_params[0]] = FormatTime(startValue.value);
      params.value[keys_params[1]] = FormatTime(endValue.value);
      params.value[keys_params[2]] = activeLable.value;
      params.value[keys_params[3]] = userIdList_tr;
      params.value[keys_params[4]] = searchContent.value;
      if (type.value) params.value[keys_params[5]] = type.value;
      context.emit('getList');
    };

    // 页面跳转
    const router = useRouter();
    const sendJump = pathName => {
      router.push({ name: pathName });
    };

    // 发布者筛选
    const treeData = ref([]);
    const userIdList = ref([]);
    const publisherFiltering = async () => {
      const { data } = await api.publisherFiltering();
      treeData.value = data;
    };

    const positivenegative = value => {
      params.value.sort = value;
    };
    onMounted(() => {
      publisherFiltering();
    });

    return {
      getButtonVisible,
      // 发布者筛选
      replaceFields,
      treeData,
      userIdList,
      // 方法
      screenSearch,
      sendJump,

      // 日期
      startValue,
      endValue,
      // positivenegative,
      endOpen,
      disabledStartDate,
      disabledEndDate,
      handleEndOpenChange,
      // 搜索框内容
      searchContent,
      // 类型
      type,
      // 标签筛选
      labelList,
      activeLable
    };
  }
};
</script>

<style lang="less" scoped>
@import '~@less/public.less';
.search-box {
  width: 1400px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 40px;
  position: relative;
  top: -25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search-screen {
    display: flex;
    align-items: center;
    .time-screen-box,
    .tag-filtering-box,
    .publisher-filtering-box {
      position: relative;
    }
    .time-screen-box {
      align-items: center;
      display: flex;
      .horizontal-line {
        margin: 0 10px;
        font-size: 21px;
      }
    }
    .tag-filtering-box {
      width: 200px;
      margin: 0 20px;
    }
    .all-type {
      .ant-select {
        width: 106px;
        margin-right: 20px;
        ::v-deep(.ant-select-selector) {
          border-radius: 5px;
          height: 30px;
        }
        ::v-deep(.ant-select-selection-placeholder) {
          color: #333333;
        }
      }
    }
    .publisher-filtering-box {
      width: 200px;
      margin: 0 20px 0 0;
    }
    .search-input {
      width: 250px;
      ::v-deep(.ant-input) {
        height: 30px;
        font-size: 14px;
        padding-left: 20px;
        &::placeholder {
          color: #999999;
        }
        border-radius: 5px 0 0 5px;
      }
      ::v-deep(.ant-btn-lg) {
        height: 30px;
        font-size: 14px;
        border-radius: 0 5px 5px 0;
      }
    }
  }
  .launch-live {
    height: 30px;
    border-radius: 5px;
    border: none;
    background-color: #ff872c;
  }
}
</style>
